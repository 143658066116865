import ThankYou from ".";
import ThankYouLegacy from "./index.legacy.js";
import { useEffect, useState } from "react";
import { sendAmplitudeData } from "../../res/amplitude";


const ABtest = () => {
  const [experiments, setExperiments] = useState({});
  useEffect(() => {
    try {
      const lstorage = localStorage.getItem('xprmt');
      let xprmt = JSON.parse(lstorage);
      if (!xprmt) xprmt = {}
      if (!xprmt.hasOwnProperty('id007')) {
        xprmt = { ...xprmt, id007: Math.floor(Math.random() * 2) };
      }
      setExperiments(xprmt);
      localStorage.setItem('xprmt', JSON.stringify(xprmt));
      sendAmplitudeData('WEB_SIGNUP_EXPERIMENTS', xprmt);
    } catch (e) { console.log(e); }
  }, []);
  useEffect(() => {
  }, [experiments]);

  return (experiments && experiments.id007) ? <ThankYou /> : <ThankYouLegacy />;
}

export default ABtest;
import { useTranslation } from 'react-i18next';
import Review from "../Templates/Review";


const LastNameRightPane = () => {
  const { t } = useTranslation();
  return (
    <Review
      name={t('subpane_lastname_name')}
      store={t('subpane_lastname_store')}
      review={t('subpane_lastname_review')} />
  );
}

export default LastNameRightPane;
import React, { useContext, createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { sendAmplitudeData } from './res/amplitude';
import qs from 'querystring';
import { country, host } from './config';

const UserDataContext = createContext();

const useUserData = () => {
  return useContext(UserDataContext);
};

const UserDataProvider = ({ children }) => {
  const [userData, setUserDataHook] = useState({
    email: '',
    phoneNumber: '',
    promocode: '',
    firstname: '',
    lastname: '',
    password: '',
  });
  const location = useLocation();
  useEffect(() => {
    const sessionUserData = JSON.parse(localStorage.getItem('userData'));
    if (sessionUserData) setUserData({ ...sessionUserData, country });
    const usable =
      location.search[0] === '?' ? location.search.slice(1) : location.search;
    const params = qs.parse(usable);
    if (params.r) {
      // promocode
      setUserData({ ...userData, promocode: params.r }, true);
      sendAmplitudeData('WEB_SIGNUP_USEDPROMOCODELINK', {
        promocode: params.r,
      });
    }
    if (params.token) {
      try {
        const data = JSON.parse(atob(params.token.replace(/\u200B/g, '')));
        sendAmplitudeData('WEB_SIGNUP_SOURCE_AFFILATE', {
          source: 'HelloBank',
          id: data?.Uuid,
          timestamp: data?.TimeStamp,
          marketplaceitem: data?.MarketPlaceItem,
        });
      } catch (e) {
        console.error('Parsing error:', e);
      }
    }
    if (params.ref) {
      sendAmplitudeData('WEB_SIGNUP_SOURCE_AFFILATE', {
        source: 'Tapfiliate',
        id: params.ref,
      });
    }
    if (params.d) {
      // if url user data
      try {
        const data = JSON.parse(atob(params.d.replace(/\u200B/g, '')));
        let newUserData = country === 'be' ? {
          firstname: data.firstName,
          lastname: data.lastName,
          accountType:
            data.accountType,
          VATType: data.VATType === 'subjectToVAT' ? 'subjectToVAT' : 'noVAT',
          ...(data.noVATStatus && { noVATStatus: 'franchisee' }),
          VATNumber: data.VATNumber,
          email: data.email,
          phoneNumber: data.phoneNumber,
          promocode: data.promocode,
        } : {
          // TODO: type this!
          rasoRef: data.rasoRef,
          firstname: data.firstName,
          lastname: data.lastName,
          accountType:
            data.accountType === 'freiberufler_principal_vat'
              ? 'freiberufler_principal_vat'
              : 'gewerbetreibender_principal',
          VATType: data.VATType === 'subjectToVAT' ? 'subjectToVAT' : 'noVAT',
          ...(data.noVATStatus && { noVATStatus: 'franchisee' }),
          ...(data.VATReturnFrequency && {
            VATReturnFrequency: data.VATReturnFrequency,
          }),
          email: data.email,
          phoneNumber: data.phoneNumber,
          promocode: data.promocode,
        };
        const fetchData = async () => {
          if (newUserData.VATNumber) {
            const response = await fetch(
              `${host}/api/v1/search-enterprises?VATNumber=${newUserData.VATNumber}`
            );
            const json = await response.json();
            if (Array.isArray(json) && json.length > 0) {
              newUserData = { ...newUserData, ...json[0] }
            }
          }
          setUserData({
            ...userData,
            ...newUserData,
          });
        }

        fetchData()
          .catch(console.error);
      } catch (e) {
        console.error('Parsing error:', e);
      }
    }
  }, []);
  const setUserData = (newUserData, save = false) => {
    if (save) {
      const toSave = { ...newUserData, password: '' };
      localStorage.setItem('userData', JSON.stringify(toSave));
    }
    setUserDataHook(newUserData);
  };
  return (
    <UserDataContext.Provider value={{ userData, setUserData }}>
      {children}
    </UserDataContext.Provider>
  );
};

export { UserDataContext, useUserData, UserDataProvider };

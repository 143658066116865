import { useTranslation } from 'react-i18next';
import Block from '../Templates/Block';
import BigCheckImg from './bigcheck.png';
import Checkmark from './checkmark.svg';
import boldFormatter from '../../../../../res/boldFormatter';


const AccountTypeDEPane = () => {
  const { t } = useTranslation();
  return (
    <Block
      headline={t('subpane_accounttypede_headline')}
      subheadline={t('subpane_accounttypede_subheadline')}
    >
      <div className="rightpane-block-bullets">
        <span className="rightpane-block-bullet">{t('subpane_accounttypede_1')}<img
          src={Checkmark}
          alt=''
          className="rightpane-block-smallcheckmark"
        /></span>
        <span className="rightpane-block-bullet">{t('subpane_accounttypede_2')}<img
          src={Checkmark}
          alt=''
          className="rightpane-block-smallcheckmark"
        /></span>
        <span className="rightpane-block-bullet">{t('subpane_accounttypede_3')}<img
          src={Checkmark}
          alt=''
          className="rightpane-block-smallcheckmark"
        /></span>
        <span className="rightpane-block-bullet">{t('subpane_accounttypede_4')}<img
          src={Checkmark}
          alt=''
          className="rightpane-block-smallcheckmark"
        /></span>
      </div>
      <img
        src={BigCheckImg}
        alt=''
        className="rightpane-block-checkmark"
      />
    </Block>
  );
}

export default AccountTypeDEPane;
//DE
export const host = 'https://app.accountable.eu';
export const webapp = 'https://web.accountable.eu';
export const intercomId = 'c9wnmh12'; //c9wnmh12 for prod
export const amplitudeKey = '068667cf512ab58a86f8123c64bfdee6'; // 068667cf512ab58a86f8123c64bfdee6 for prod
export const fbPixelId = '2207534789479329'; //its prod
export const gtagId = 'GTM-5SW8SVC';
export const country = 'de';

//BE staging
// export const host = 'https://app.accountable.eu';
// export const webapp = 'https://web.accountable.eu';
// export const intercomId = 'llxhxzz5'; //c9wnmh12 for prod
// export const amplitudeKey = '32bc5dcaf02c179823553bea10a420fa'; // 068667cf512ab58a86f8123c64bfdee6 for prod
// export const fbPixelId = '2207534789479329'; //its prod
// export const gtagId = 'GTM-5SW8SVC';
// export const country = 'de';

//BE
// export const host = 'https://app.accountable.eu';
// export const webapp = 'https://web.accountable.eu';
// export const intercomId = 'c9wnmh12'; //c9wnmh12 for prod
// export const amplitudeKey = '068667cf512ab58a86f8123c64bfdee6'; // 068667cf512ab58a86f8123c64bfdee6 for prod
// export const fbPixelId = '2207534789479329'; //its prod
// export const gtagId = 'GTM-W6JQ8MX';
// export const country = 'be';

export const tapFiliateId = '24869-f877d5';

import './review.css';
import starsPic from './stars.svg';

const Review = ({ name, store, review }) =>
  <div className="review">
    <span className='review-name'>{name}</span> <span className='review-store'>{store}</span>
    <img
      src={starsPic}
      alt='5 stars'
      className="testimonial_stars"
    />
    <p className='review-p'>
      {review}
    </p>
  </div>
export default Review;
import { useTranslation } from 'react-i18next';
import Block from '../Templates/Block';
import ChatImg from './country.png';


const CountryRightPane = () => {
  const { t } = useTranslation();
  return (
    <Block
      headline={t('subpane_country_headline')}
      subheadline={t('subpane_country_subheadline')}
    ><img
        src={ChatImg}
        alt=''
        className=""
        width='100%'
      /></Block>
  );
}

export default CountryRightPane;
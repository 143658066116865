import { useTranslation } from 'react-i18next';
import Block from '../Templates/Block';
import TeamImg from './team.png';
import boldFormatter from '../../../../../res/boldFormatter';


const VATTypePane = () => {
  const { t } = useTranslation();
  return (
    <Block
      headline={t('subpane_vattype_headline')}
      subheadline={t('subpane_vattype_subheadline')}
    >
      <p className="rightpane-block-p">
        {boldFormatter(t('subpane_vattype_p'))}
      </p>
      <img
        src={TeamImg}
        alt=''
        className="rightpane-block-man"
      /></Block>
  );
}

export default VATTypePane;

import LogoImg from '../../../res/images/logo.svg';

const RightPane = ({ children }) => {

  return (
    <div className='onboarding-right'>

      <div className="onboarding-right_logo">
        <img src={LogoImg} alt="Accountable" />
      </div>
      {children}
    </div>);
};

export default RightPane;
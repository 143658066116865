import { useTranslation } from 'react-i18next';
import Block from '../Templates/Block';
import BadgesImg from './badges.svg';
import Trustmark from './trustmark.png';
import boldFormatter from '../../../../../res/boldFormatter';
import { country } from '../../../../../config';


const PasswordPane = () => {
  const { t } = useTranslation();
  return (
    <Block
      headline={t('subpane_password_headline')}
      subheadline={t('subpane_password_subheadline')}
    >
      <p className="rightpane-block-p">
        {boldFormatter(t('subpane_password_p'))}
      </p>
      {(country === 'de' || t('current_language') === 'en') &&
        <img
          src={BadgesImg}
          alt=''
        />
      }
      <img
        src={Trustmark}
        alt=''
        className="rightpane-block-trustmark"
      /></Block>
  );
}

export default PasswordPane;
import { useTranslation } from 'react-i18next';
import Block from '../Templates/Block';
import Checkmark from './checkmark.svg';
import boldFormatter from '../../../../../res/boldFormatter';


const PromocodePane = () => {
  const { t } = useTranslation();
  return (
    <Block
      headline={t('subpane_promocode_headline')}
      subheadline={t('subpane_promocode_subheadline')}
    >
      <div className="rightpane-block-bullets">
        <span className="rightpane-block-bullet"><img
          src={Checkmark}
          alt=''
          className="rightpane-block-smallcheckmarkr"
        />{t('subpane_promocode_1')}</span>
        <span className="rightpane-block-bullet"><img
          src={Checkmark}
          alt=''
          className="rightpane-block-smallcheckmarkr"
        />{t('subpane_promocode_2')}</span>
        <span className="rightpane-block-bullet"><img
          src={Checkmark}
          alt=''
          className="rightpane-block-smallcheckmarkr"
        />{t('subpane_promocode_3')}</span>
      </div>
    </Block>
  );
}

export default PromocodePane;
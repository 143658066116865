import { useTranslation } from 'react-i18next';
import Review from "../Templates/Review";


const CompanyNamePane = () => {
  const { t } = useTranslation();
  return (
    <Review
      name={t('subpane_companyname_name')}
      store={t('subpane_companyname_store')}
      review={t('subpane_companyname_review')} />
  );
}

export default CompanyNamePane;
import { useTranslation } from 'react-i18next';
import Block from '../Templates/Block';
import ManImg from './man.png';
import boldFormatter from '../../../../../res/boldFormatter';


const ComplementaryDaysPane = () => {
  const { t } = useTranslation();
  return (
    <Block
      headline={t('subpane_complementarydays_headline')}
      subheadline={t('subpane_complementarydays_subheadline')}
    >
      <p className="rightpane-block-p">
        {boldFormatter(t('subpane_complementarydays_p'))}
      </p>
      <img
        src={ManImg}
        alt=''
        className="rightpane-block-man2"
      /></Block>
  );
}

export default ComplementaryDaysPane;
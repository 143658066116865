import { useTranslation } from 'react-i18next';
import Review from "../Templates/Review";


const FirstNameRightPane = () => {
  const { t } = useTranslation();
  return (
    <Review
      name={t('subpane_firstname_name')}
      store={t('subpane_firstname_store')}
      review={t('subpane_firstname_review')} />
  );
}

export default FirstNameRightPane;
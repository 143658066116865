import React from 'react';
import { useTranslation } from 'react-i18next';
import './badges.css';
import BafinImg from './bafin.png';
import ElsterImg from './elster.png';
import HostedInGermanyImg from './hostedingermany.png';
import MadeInBE from './madeinbe.svg';

const Badges = () => {

  const { t } = useTranslation();
  return (
    <div className="badges">
      {t('current_language') === 'en' || t('current_language') === 'de' ?
        <>
          <img src={BafinImg} alt="Bafin" />
          <img src={HostedInGermanyImg} alt="Hosted in Germany" />
          <img src={ElsterImg} alt="ELSTER" />      </> :
        <img src={MadeInBE} alt="Made in Belgium" />
      }
    </div>
  );
};
export default Badges;

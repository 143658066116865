import React, { useState, useEffect } from 'react';
import './onboarding.css';
import ProgressBar from './ProgressBar';
import Question from './Question/index';
import BusinessSelector from './BusinessSelector';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import { useUserData } from '../../userData';
import { country, host } from '../../config';
import { emailValidator } from '../Signup/Form/validators';
import Loader from './Loader';
import { setIntercomEmail } from '../../res/intercom';
import { setAmplitudeUserId } from '../../res/amplitude';
import qs from 'querystring';
import BottomBanner from './BottomBanner';
import { signup } from '../Signup/Form/signup';
import GoogleBtn from './GoogleBtn';
import { GTMsendEvent } from '../../res/gtag';
import './variation.css';
import Badges from '../Signup/Badges';
import Checkmarks from '../Signup/Checkmarks';
import RightPane from './RightPane';
import EmailRightPane from './RightPane/Subpanes/EmailRightPane';
import FirstNameRightPane from './RightPane/Subpanes/FirstNameRightPane';
import LastNameRightPane from './RightPane/Subpanes/LastNameRightPane';
import CountryRightPane from './RightPane/Subpanes/CountryRightPane';
import RegistrationDateDEPane from './RightPane/Subpanes/RegistrationDateDEPane';
import AccountTypeDEPane from './RightPane/Subpanes/AccountTypeDEPane';
import VATTypeDEPane from './RightPane/Subpanes/VATTypeDEPane';
import VATReturnFrequencyDEPane from './RightPane/Subpanes/VATReturnFrequencyDEPane';
import PromocodePane from './RightPane/Subpanes/PromocodePane';
import PasswordPane from './RightPane/Subpanes/PasswordPane';
import AccountTypePane from './RightPane/Subpanes/AccountTypePane';
import CompanyNamePane from './RightPane/Subpanes/CompanyNamePane';
import OccupationPane from './RightPane/Subpanes/OccupationPane';
import ComplementaryDaysPane from './RightPane/Subpanes/ComplementaryDaysPane';
import VATTypePane from './RightPane/Subpanes/VATTypePane';

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

const Onboarding = () => {
  const [step, setStep] = useState('email');
  const { t } = useTranslation();
  const { userData, setUserData } = useUserData(); //set UserData for thank you modal
  const [status, setStatus] = useState(''); // status either loading/failed/empty/redirect
  const [businessInfoList, setbusinessInfoList] = useState([]);
  const [formData, setFormData] = useState({}); // Contains all the form data from the steps
  const location = useLocation();
  useEffect(async () => {
    if (userData.rasoRef) {
      const emailExists = await emailValidator(userData.email);
      if (!emailExists) setStatus('redirect');
      return;
    }
    if (userData.promocode)
      setFormData({ ...formData, promocode: userData.promocode });
  }, [userData]);
  useEffect(() => {
    const usable =
      location.search[0] === '?' ? location.search.slice(1) : location.search;
    const params = qs.parse(usable);
    if (params.email) {
      nextStep({ email: params.email, queryEmail: true });
    }
  }, []);

  const nextStep = async (data, currentStep = step) => {
    // Go to next step if last step submit to backend
    const newData = {
      ...formData,
      ...data,
      ...(userData.promocode && { promocode: userData.promocode }),
      language: t('current_language'),
    };
    setFormData(newData);
    if (currentStep === 'email') {
      setIntercomEmail(newData.email, t('current_language'));
      setAmplitudeUserId(newData.email);
      GTMsendEvent('WSEmail');
      setStep('firstname');
    } else if (currentStep === 'firstname') {
      setStep('lastname');
    } else if (currentStep === 'lastname') {
      if (newData.language === 'fr' || newData.language === 'nl') {
        setFormData({ ...newData, country: 'be' });
        setStep('accountType');
      } else if (newData.language === 'de') {
        setFormData({ ...newData, country: 'de' });
        setStep('accountTypeDE');
      } else {
        setStep('country');
      }
    } else if (currentStep === 'country') {
      setUserData(
        {
          ...userData,
          country: newData.country,
        },
        true
      );
      if (newData.country === 'de') {
        setStep('accountTypeDE');
      } else {
        setStep('accountType');
      }
    } else if (currentStep === 'accountTypeDE') {
      setStep('VATTypeDE');
    } else if (currentStep === 'VATTypeDE') {
      if (newData.VATType === 'subjectToVAT') {
        setStep('VATReturnFrequencyDE');
      } else {
        setStep('promocode');
      }
    } else if (currentStep === 'VATReturnFrequencyDE') {
      setStep('RegistrationDateDE');
    } else if (currentStep === 'RegistrationDateDE') {
      setStep('promocode');
    } else if (currentStep === 'accountType') {
      //Step 3
      if (data.accountType !== 'one_person_company') {
        setStep('loader');
      } else {
        setStep('companyName');
      }
    } else if (currentStep === 'companyName') {
      //Step 4
      setStep('loader');
    } else if (currentStep === 'loader') {
      //Step 5
      const req = await getBusinessInfo(
        formData.accountType === 'one_person_company'
          ? formData.companyName
          : formData.firstname
      ); // if 1-man-co use the company name else use the first and last name
      setbusinessInfoList(req.slice(0, 5));
      if (req.length === 0) setStep('VATNumber');
      else setStep('businessSelector');
    } else if (currentStep === 'businessSelector') {
      //Step 6
      if (!data.VATNumber) {
        setStep('VATNumber');
      } else if (formData.accountType === 'one_person_company') {
        setStep('VATType');
      } else if (formData.accountType === 'natural_person_student_vat') {
        setStep('complementaryDays');
      } else {
        setStep('occupation');
      }
    } else if (currentStep === 'VATNumber') {
      //7
      if (formData.accountType === 'one_person_company') {
        setStep('VATType');
      } else if (formData.accountType === 'natural_person_student_vat') {
        setStep('complementaryDays');
      } else {
        setStep('occupation');
      }
    } else if (currentStep === 'occupation') {
      //8
      if (data.occupation === '_principal_vat') {
        setStep('VATType');
      } else {
        setStep('complementaryDays');
      }
    } else if (currentStep === 'complementaryDays') {
      // 9
      setStep('VATType');
    } else if (currentStep === 'VATType') {
      // 10
      setStep('promocode');
    } else if (currentStep === 'promocode') {
      if (newData.promocode && newData.promocode.toLowerCase() === 'taxleu')
        fetch(
          //spin up the lambda function
          `https://accountablemuj3pl9f-promocodetogsheet.functions.fnc.fr-par.scw.cloud`
        );
      // 11
      setStep('password');
    } else if (currentStep === 'password') {
      // 12
      setStatus('loading');
      const emailExists = await emailValidator(newData.email);
      if (!emailExists) {
        setStatus('redirect');
        return;
      }
      const signedUp = await signup(newData);
      console.log("userData", userData);
      setUserData(
        {
          ...userData,
          password: newData.password,
          email: newData.email,
          phoneNumber: newData.phoneNumber,
          firstname: userData.firstname,
          lastname: newData.lastname
        },
        true
      );
      if (signedUp) setStatus('redirect');
      else setStatus('failed');
    }
  };
  const getBusinessInfo = async (name) => {
    try {
      const response = await fetch(
        `${host}/api/v1/search-enterprises?name=${name}`
      );
      const json = await response.json();
      if (!Array.isArray(json)) {
        return [];
      } else {
        return json;
      }
    } catch (e) {
      console.log('err', e);
      return [];
    }
  };
  const steps = {
    email: (
      <Question
        subhead={t('question_email_subheadline')}
        head={t('question_email_headline')}
        button={t('form_continue')}
        input={{
          type: 'text',
          name: 'email',
          placeholder: t('email_placeholder'),
          refParams: {
            required: true,
            validate: emailValidator,
            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          },
        }}
        nextStep={nextStep}
        key="1"
      />
    ),
    firstname: (
      <Question
        subhead={formData.queryEmail ? t('question_name_subheadline') : ' '}
        head={t('question_name_headline')}
        button={t('form_continue')}
        input={{
          type: 'text',
          name: 'firstname',
          placeholder: t('first_name_placeholder'),
          refParams: {
            required: true,
            maxLength: 80,
          },
        }}
        nextStep={nextStep}
        previousStep={() => setStep('email')}
        key="2"
      />
    ),
    lastname: (
      <Question
        subhead={formData.queryEmail ? t('question_lastname_subheadline') : ' '}
        head={t('question_lastname_headline')}
        button={t('form_continue')}
        input={{
          type: 'text',
          name: 'lastname',
          placeholder: t('last_name_placeholder'),
          refParams: {
            required: true,
            maxLength: 80,
          },
        }}
        nextStep={nextStep}
        previousStep={() => setStep('firstname')}
        key="999"
      />
    ),
    country: (
      <Question
        subhead={t('question_country_subheadline')}
        head={t('question_country_headline')}
        button={t('form_continue')}
        input={{
          type: 'picker',
          name: 'country',
          options: [
            {
              name: t('question_country_germany'),
              value: 'de',
            },
            {
              name: t('question_country_belgium'),
              value: 'be',
            },
          ],
        }}
        nextStep={nextStep}
        key="country"
        previousStep={() => setStep('lastname')}
      />
    ),
    accountType: (
      <Question
        subhead={t('question_business_subheadline', {
          name:
            formData.firstname &&
            capitalizeFirstLetter(formData.firstname.split(' ')[0]),
        })}
        head={t('question_business_headline')}
        button={t('form_continue')}
        input={{
          type: 'picker',
          name: 'accountType',
          options: [
            {
              name: t('question_tax_status_natural_person'),
              description: t('question_tax_status_natural_person_desc'),
              value: 'natural_person',
            },
            {
              name: t('question_tax_status_legal_person'),
              description: t('question_tax_status_legal_person_desc'),
              value: 'one_person_company',
            },
            {
              name: t('question_tax_status_student'),
              value: 'natural_person_student_vat',
            },
            {
              name: t('question_tax_status_not_selfemployed'),
              value: 'natural_person',
              skipper: true,
            },
          ],
        }}
        nextStep={nextStep}


        previousStep=
        {
          t('current_language') === 'fr' || t('current_language') === 'nl' || t('current_language') === 'de' ?
            () => setStep('firstname')
            : () => setStep('country')
        }
        key="3"
      />
    ),
    companyName: (
      <Question
        subhead={t('question_limitedcompany_subheadline')}
        head={t('question_limitedcompany_headline')}
        button={t('form_continue')}
        input={{
          type: 'text',
          name: 'companyName',
          placeholder: t('company_name_placeholder'),
          refParams: {
            required: true,
            minLength: 2,
            maxLength: 80,
          },
        }}
        nextStep={nextStep}
        key="4"
        previousStep={() => setStep('accountType')}
      />
    ),
    loader: (
      <Loader
        subhead={t('loader_search_for_business')}
        nextStep={nextStep}
        key="5"
        previousStep={() => setStep('accountType')}
      />
    ),
    businessSelector: (
      <BusinessSelector
        subhead={t('question_select_your_business')}
        businessInfoList={businessInfoList}
        nextStep={nextStep}
        key="6"
        previousStep={() => setStep('accountType')}
      />
    ),
    VATNumber: (
      <Question
        subhead={t('question_vatnumber_subheadline')}
        head={t('question_vatnumber_headline')}
        button={t('form_continue')}
        input={{
          type: 'VATNumber',
          name: 'VATNumber',
          refParams: {
            maxLength: 12,
          },
        }}
        nextStep={nextStep}
        skipable={t('question_vatnumber_skipable')}
        key="7"
        previousStep={() => setStep('accountType')}
      />
    ),
    occupation: (
      <Question
        subhead={t('question_occupation_subheadline')}
        head={t('question_occupation_headline')}
        button={t('form_continue')}
        input={{
          type: 'picker',
          name: 'occupation',
          options: [
            {
              name: t('question_occupation_principal'),
              value: '_principal_vat',
            },
            {
              name: t('question_occupation_secondary'),
              description: t('question_occupation_secondary_desc'),
              value: '_complementary',
            },
          ],
        }}
        nextStep={nextStep}
        previousStep={() => setStep('accountType')}
        key="8"
      />
    ),
    complementaryDays: (
      <Question
        subhead={t('question_complementarydays_subheadline')}
        head={t('question_complementarydays_headline')}
        button={t('form_continue')}
        input={{
          type: 'dayspicker',
          name: 'complementaryDays',
          options: [
            { name: '1', value: 1 },
            { name: '2', value: 2 },
            { name: '3', value: 3 },
            { name: '4', value: 4 },
            { name: '5', value: 5 },
          ],
        }}
        nextStep={nextStep}
        previousStep={() => setStep('occupation')}
        key="9"
      />
    ),
    VATType: (
      <Question
        subhead={t('question_subjectvat_subheadline')}
        head={t('question_subject_headline')}
        button={t('form_continue')}
        input={{
          type: 'picker',
          name: 'VATType',
          options: [
            {
              name: t('question_subjectvat_subject'),
              value: 'subjectToVAT',
            },
            {
              name: t('question_subjectvat_franchisee'),
              description: t('question_subjectvat_franchisee_desc'),
              value: 'franchisee',
            },
            {
              name: t('question_subjectvat_exempt'),
              value: 'exempt',
            },
          ],
        }}
        nextStep={nextStep}
        previousStep={() => setStep('occupation')}
        key="10"
      />
    ),
    promocode: (
      <Question
        subhead={t('question_promocode_subheadline')}
        head={t('question_promocode_headline')}
        button={t('form_continue')}
        input={{
          type: 'text',
          name: 'promocode',
          refParams: {
            maxLength: 80,
          },
          disabled: userData.promocode,
          value: userData.promocode,
        }}
        nextStep={nextStep}
        previousStep={
          (formData.country === "be") ?
            () => setStep('VATType')
            : () => setStep('VATTypeDE')
        }
        skipable={t('question_promocode_skipable')}
        key="11"
      />
    ),
    password: (
      <Question
        subhead={t('question_password_subheadline')}
        head={t('question_password_headline')}
        button={t('form_finish')}
        loadingAnimation={status}
        input={{
          type: 'password',
          name: 'password',
          refParams: {
            required: true,
            minLength: 8,
            maxLength: 80,
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
          },
        }}
        nextStep={nextStep}
        previousStep={() => setStep('promocode')}
        confettis={true}
        key="12"
      />
    ),
    accountTypeDE: (
      <Question
        subhead={t('question_account_type_subheadline', {
          name:
            formData.firstname &&
            capitalizeFirstLetter(formData.firstname.split(' ')[0]),
        })}
        head={t('question_account_type_headline')}
        button={t('form_continue')}
        input={{
          type: 'picker',
          name: 'accountType',
          options: [
            {
              name: t('question_account_type_freiberufler'),
              value: 'freiberufler_principal_vat',
            },
            {
              name: t('question_account_type_gewerbetreibender'),
              value: 'gewerbetreibender_principal',
            },
          ],
        }}
        nextStep={nextStep}
        previousStep={t('current_language') === 'de' ?
          () => setStep('lastname')
          : () => setStep('country')}
        helperContent={t('helper_accounttype')}
        key="12"
      />
    ),
    VATTypeDE: (
      <Question
        subhead={t('question_vat_type_subheadline')}
        head={t('question_vat_type_headline')}
        button={t('form_continue')}
        input={{
          type: 'picker',
          name: 'VATType',
          options: [
            {
              name: t('question_subjectvat_subject'),
              value: 'subjectToVAT',
            },
            {
              name: t('question_subjectvat_franchisee'),
              description: t('question_subjectvat_franchisee_desc_de'),
              value: 'franchisee',
            },
            {
              name: t('question_subjectvat_exempt'),
              value: 'exempt',
            },
          ],
        }}
        nextStep={nextStep}
        previousStep={() => setStep('accountTypeDE')}
        helperContent={t('helper_vattype')}
        key="13"
      />
    ),
    VATReturnFrequencyDE: (
      <Question
        subhead={t('question_vat_return_frequency_subheadline')}
        head={t('question_vat_return_frequency_headline')}
        button={t('form_continue')}
        input={{
          type: 'picker',
          name: 'VATReturnFrequency',
          options: [
            {
              name: t('monthly_vat'),
              value: 'monthly',
            },
            {
              name: t('quarterly_vat'),
              value: 'quarterly',
            },
            {
              name: t('yearly_vat'),
              value: 'yearly',
            },
          ],
        }}
        nextStep={nextStep}
        previousStep={() => setStep('VATTypeDE')}
        key="14"
      />
    ),
    RegistrationDateDE: (
      <Question
        head={t('regstration_date')}
        button={t('form_continue')}
        input={{
          type: 'picker',
          name: 'regstration_date',
          options: [
            {
              name: t('regstration_date_less_than_a_year'),
              value: 'less_than_1year_ago',
            },
            {
              name: t('regstration_date_more_than_a_year'),
              value: 'more_than_1year_ago',
            },
          ],
        }}
        previousStep={() => setStep('VATReturnFrequencyDE')}
        nextStep={nextStep}
        key="15"
      />
    ),
  };
  const subpanes = {
    email: <EmailRightPane />,
    firstname: <FirstNameRightPane />,
    lastname: <LastNameRightPane />,
    country: <CountryRightPane />,
    RegistrationDateDE: <RegistrationDateDEPane />,
    accountTypeDE: <AccountTypeDEPane />,
    VATTypeDE: <VATTypeDEPane />,
    VATReturnFrequencyDE: <VATReturnFrequencyDEPane />,
    promocode: <PromocodePane />,
    password: <PasswordPane />,
    accountType: <AccountTypePane />,
    businessSelector: <CompanyNamePane />,
    VATNumber: <AccountTypePane />,
    companyName: <CompanyNamePane />,
    occupation: <OccupationPane />,
    complementaryDays: <ComplementaryDaysPane />,
    VATType: <VATTypePane />
  };

  const progressbarSteps = {
    email: 0,
    firstname: 0,
    lastname: 0,
    country: 1,
    accountType: 1,
    companyName: 2,
    loader: 2,
    businessSelector: 2,
    VATNumber: 2,
    occupation: 3,
    complementaryDays: 3,
    VATType: 4,
    promocode: 5,
    password: 6,
    accountTypeDE: 1,
    VATTypeDE: 4,
    VATReturnFrequencyDE: 4,
    RegistrationDateDE: 4,
  };
  const stepsLabel = [
    t('tab_personal_info'),
    t('tab_tax_status'),
    t('tab_business_info'),
    t('tab_occupation'),
    t('tab_vat_status'),
    t('tab_promocode'),
    t('tab_password'),
  ];
  return (
    <div className="onboarding">
      <div className="onboarding-flex">
        <div className="onboarding-left">
          <ProgressBar
            progressbarSteps={progressbarSteps}
            step={progressbarSteps[step]}
            labels={stepsLabel}
            nextStep={nextStep}
          />
          {steps[step]}
          {step === 'email' &&
            <GoogleBtn nextStep={nextStep} />
          }
          <div className='badges-flex'>
            <Badges />
            <Checkmarks />
          </div>

        </div>
        <RightPane>
          {subpanes[step] && <>
            {subpanes[step]}
          </>}
        </RightPane>
      </div>
      {
        status === 'redirect' && (
          <Redirect
            to={country === 'de' ? `${t('thank_you_page_de')}${location.search}` : `${t('thank_you_page')}${location.search}`}
          />
        )
      }
    </div>
  );
};
export default Onboarding;

import { amplitudeKey } from '../config';
import * as amplitude from '@amplitude/analytics-browser';


export const initAmplitude = () => {
  amplitude.init(amplitudeKey);
};

export const setAmplitudeUserId = (userId, timeout = 500) => {
  if (amplitude.getSessionId()) {
    amplitude.setUserId(userId);
  } else {
    setTimeout(() => {
      setAmplitudeUserId(userId, timeout * 2);
    }, timeout);
  }
};

export const setAmplitudeUserProperties = (properties, timeout = 500) => {
  const identifyObj = new amplitude.Identify();
  if (amplitude.getSessionId()) {
    for (const [key, value] of Object.entries(properties)) {
      identifyObj.set(key, value);
    }
    amplitude.identify(identifyObj);
  } else {
    setTimeout(() => {
      setAmplitudeUserProperties(properties, timeout * 2);
    }, timeout);
  }
};

export const sendAmplitudeData = (
  eventType,
  eventProperties = {},
  timeout = 500
) => {
  if (amplitude.getSessionId()) {
    amplitude.track(eventType, eventProperties);
  } else {
    setTimeout(() => {
      sendAmplitudeData(eventType, eventProperties, timeout * 2);
    }, timeout);
  }
};

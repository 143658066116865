import { useTranslation } from 'react-i18next';
import Review from "../Templates/Review";
import LaptopEN from './laptop_en.png';
import LaptopDE from './laptop_de.png';
import LaptopNL from './laptop_nl.png';
import LaptopFR from './laptop_fr.png';

import './emailrightpane.css';


const EmailRightPane = () => {
  const { t } = useTranslation();
  return (<div>
    <img
      src={t('current_language') === 'de' ? LaptopDE :
        t('current_language') === 'fr' ? LaptopFR :
          t('current_language') === 'nl' ? LaptopNL :
            LaptopEN}
      alt=''
      className="email-pane_laptop"
    />
    <br />
    <Review
      name={t('subpane_email_name')}
      store={t('subpane_email_store')}
      review={t('subpane_email_review')} />
  </div>);
}

export default EmailRightPane;